import React from 'react';
import { graphql, Link, useStaticQuery } from "gatsby"
import './assets/styles/_index.scss';
import { getWhatsAppURL } from '../../site/utils';

const AreaDetails = (props) => {

    const data = useStaticQuery(graphql`
    query GetContactInfoArea {
      glstrapi {
        siteConfig {
          email
          phone
          address_link
          address
        }
      }
    }
  `)

  const contact = data.glstrapi.siteConfig

    return (
        <div className="nego-details-wrapper area-details">
            <div className="area-details-wrapper">
                <div className="area-details-heading">List Your Property in {props?.areaData?.name}</div>
                {/* <div className="area-details-desc">{"Lorem ipsum dolor sit amet conse ctetur adipiscing elit."}</div> */}
            </div>
            <div className="nego-btn-wrapper">
                <Link to="/contact/list-a-property/" className="button button-primary">List a Property</Link>
                <a href={"tel:" + contact.phone} className="button button-secondary"><i className="icon icon-nego-phone"></i> {contact.phone}</a>
                <a href={getWhatsAppURL(contact.phone)} target={"_blank"} className="button button-secondary mb-0"><i className="icon icon-nego-whatsapp"></i> Click to Whatsapp</a>
            </div>
        </div>
    )
}

export default AreaDetails