import React from 'react';
import { Container, Col, Row, Breadcrumb } from "react-bootstrap";
import './assets/styles/_index.scss';

const ImageContent = (props) => {
    if (!props?.content?.image1 || props?.content?.image2) return ''
    return (
        <section className="image-content-wrapper">
            {
                (
                    props?.content?.image1 && props?.content?.image2 ? 
                    <Row>
                        <Col md={6}>
                            <div className="image-content-zoom img-small">
                                <img src={props?.content?.image1?.url} />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="image-content-zoom img-small">
                                <img src={props?.content?.image2?.url} />
                            </div>
                        </Col>
                    </Row>
                    : 
                    <Row>
                        <Col>
                            <div className="image-content-zoom img-big">
                                <img src={props?.content?.image1?.url} />
                            </div>
                        </Col>
                    </Row>
                )
            }
        </section>
    )
}

export default ImageContent