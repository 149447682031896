import React, { useEffect, useState } from "react"
import { Container, Col, Row } from "react-bootstrap"
import parse from "html-react-parser"
import { graphql, Link } from "gatsby"
import { format } from "date-fns"
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share"
import Header from "../components/Header/Header"
import BreadcrumbModule from "../components/BreadcrumbModule/BreadcrumbModule"
import ImageContent from "../components/ImageContent/ImageContent"
import MoreAreasDetails from "../components/MoreAreasDetails/MoreAreasDetails"
import AreaDetails from "../components/NegoDetails/AreaDetails"
import Footer from "../components/Footer/Footer"
import SEO from "../components/Seo/seo"
import { useGgfxImage } from "../components/Seo/propertyDetailsSEO"

const AreaguideTemplate = ({ data }) => {
  const [scroll, setScroll] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 250)
    })
  }, [])

  const areaDetail = data?.glstrapi.areaGuide

  const allAreas = data?.glstrapi?.areaGuides?.filter(
    areaValue => areaDetail?.id !== areaValue?.id
  )

  const SocialData = data.glstrapi.siteConfig

  // Social share
  const shareurl = typeof window !== "undefined" ? window.location.href : ""

  // const [Shareicons,setShareicons] = React.useState(false);

  // const openShareicons = () => {
  // setShareicons(true);
  // if(Shareicons === true) {
  // 	setShareicons(false);
  // }
  // }

  const trackerShare = event => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "Share - social icons",
      formType: event + " - " + shareurl,
      formId: "Share",
      formName: "Share",
      formLabel: "Share",
    })
  }
  // Social share

  return (
    <div className="news-details-page">
      <SEO
        title={areaDetail?.name + " | Dubai community guide"}
        description={
          "Discover the best communities in Dubai with White & Co Real Estate real estate. We will keep you updated about all the new attractions in Dubai, things to do and places to visit in Dubai."
        }
        image={useGgfxImage(areaDetail)}
      />
      <Header layout={"area-details-template"} />

      <BreadcrumbModule areaData={areaDetail} tag={"areaguide"} />

      <Container>
        <Row>
          <Col xl={8}>
            <div className="news-details-heading-wrapper">
              <h1 className="news-details-heading area-heading">
                {areaDetail?.name} Area Guide
              </h1>
              <div className="news-short-desc">
                {parse(areaDetail?.description)}
              </div>
              <ul className="list-inline area-btn-wrapper d-xl-block d-none">
                {areaDetail?.ctas.map((data, i) => {
                  return (
                      <li className="list-inline-item">
                          <Link to={data?.custom_link} className="button button-primary">{data?.label}</Link>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xl={8}>
            <div className="news-text-wrapper">
              {areaDetail?.contents.map((content, i) => {
                return (
                  <>
                    {content.__typename ===
                      "GLSTRAPI_ComponentBlocksImageContent" && (
                      <ImageContent content={content} />
                    )}

                    {content.__typename ===
                      "GLSTRAPI_ComponentBlocksRichText" && (
                      <div className="image-text-content">
                        {parse(content.text_content)}
                      </div>
                    )}
                  </>
                )
              })}
            </div>

            <div className="news-description-divider"></div>
            <div className="news-details-share-heading">Share this guide</div>
            <div className="d-flex news-details-img-social">
              <div className="news-detail-social">
                <FacebookShareButton
                  onClick={() => trackerShare("FacebookShareButton")}
                  url={shareurl}
                  className="my-share-button facebook-share"
                >
                  <i className="icon icon-fb-round"></i>
                </FacebookShareButton>
              </div>
              <div className="news-detail-social">
                <TwitterShareButton
                  onClick={() => trackerShare("TwitterShareButton")}
                  url={shareurl}
                  className="my-share-button twitter-share"
                >
                  <i className="icon icon-twitter-round"></i>
                </TwitterShareButton>
              </div>
              <div className="news-detail-social">
                <a target={"_blank"} href={SocialData.instagram_link}>
                  <i className="icon icon-insta-round"></i>
                </a>
              </div>
              <div className="news-detail-social">
                <LinkedinShareButton
                  onClick={() => trackerShare("LinkedinShareButton")}
                  url={shareurl}
                  className="my-share-button linkedin-share"
                >
                  <i className="icon icon-linkedin-round"></i>
                </LinkedinShareButton>
              </div>
            </div>
          </Col>
          <Col xl={1}></Col>
          <Col xl={3} className="d-xl-block d-none">
            <div
              className={`news-right-side-details sticky-top ${
                scroll ? "scrolled" : ""
              }`}
            >
              <AreaDetails areaData={areaDetail} />
            </div>
          </Col>
        </Row>
      </Container>

      <MoreAreasDetails areaData={allAreas} categoryType="category1" />

      <Footer />
    </div>
  )
}

export default AreaguideTemplate

export const pageQuery = graphql`
  query GetAreasPage($articleId: ID!) {
    glstrapi {
      areaGuides(limit: 5) {
        id
        name
        slug
        short_description
        tile_image {
          url
        }
        ggfx_results {
          src_cftle
          transforms
        }
      }
      areaGuide(id: $articleId, publicationState: LIVE) {
        slug
        description
        id
        latitude
        longitude
        name
        tile_image {
          url
        }
        ggfx_results {
          src_cftle
          transforms
        }
        publish
        contents {
          ... on GLSTRAPI_ComponentBlocksImageContent {
            id
            __typename
            image1 {
              url
            }
            image2 {
              url
            }
          }
          ... on GLSTRAPI_ComponentBlocksRichText {
            id
            __typename
            text_content
          }
        }
        short_description
        ctas {
          label
          custom_link
          id
        }
      }
      siteConfig {
        instagram_link
      }
    }
  }
`
