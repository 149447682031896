import React from 'react';
import { Container, Col, Row, Breadcrumb } from "react-bootstrap";
import { aboutPageURL, areaguidesPageURL, newsPageURL, teamsPageURL } from '../../site/urls';
import './assets/styles/_index.scss';

const BreadcrumbModule = (props) => {

    return (
        <section className="breadcrumb-wrapper-module">
            <Container>
                <Row>
                    <Col>
                        <Breadcrumb className="breadcrumb-module ssd-flex align-items-center">
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                               { props.tag === "areaguide" && 
                                <>
                                    <Breadcrumb.Item href={aboutPageURL}>About</Breadcrumb.Item>
                                    <Breadcrumb.Item href={areaguidesPageURL}>Dubai Communities</Breadcrumb.Item>
                                    <Breadcrumb.Item active>{props?.areaData?.name} Area Guide</Breadcrumb.Item>
                                </>
                                }
                               { props.tag === "team" &&
                                <>
                                    <Breadcrumb.Item href={teamsPageURL}>People</Breadcrumb.Item>
                                    <Breadcrumb.Item active>{props?.teamsData?.name}</Breadcrumb.Item>
                                </>
                                }
                                {props.tag === "news" && 
                                <>
                                    <Breadcrumb.Item href={newsPageURL}>News</Breadcrumb.Item>
                                    <Breadcrumb.Item active>{props?.newsData?.title}</Breadcrumb.Item>
                                </>
                                }
                                {props.tag === "form_page" && props.data?.map(item => 
                                <>
                                    <Breadcrumb.Item href={item.url} active={item.active}>{item.name}</Breadcrumb.Item>
                                </>
                                )
                                }
                            
                        </Breadcrumb>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default BreadcrumbModule